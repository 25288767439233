import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Solutions from "./sections/FAQs";
import { Helmet } from "react-helmet-async";


const Item = () => {
    const { idItem } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    

    useEffect(() => {
        // Mock API call to fetch data (replace with your actual API URL)
   // Function to fetch the data
   const fetchData = async () => {
    setLoading(true);
    setError(null);
    console.log('token: '+process.env.REACT_APP_API_TOKEN)
    console.log('url: '+process.env.REACT_APP_API_URL)

    try {
      const response = await fetch("https://urlparser-a4ewfrgfakhwa2ap.centralus-01.azurewebsites.net/item", {
        method: "POST", // Use POST as per the cURL command
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: idItem }), // Send the id from the route
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log(response)

      const result = await response.json();
      setData(result);
    } catch (err) {
      setError(err.message);
      console.error("Fetch error:", err);
    } finally {
      setLoading(false);
    }
  };

        fetchData();
    }, [idItem]);

    if (loading) {
        return <div className="text-center p-10">Loading...</div>;
    }

    if (!data) {
        return <div className="text-center p-10">No data found.</div>;
    }

    if (error) {
        return <div className="text-center p-10 text-red-500">Error: {error}</div>;
      }

    const {
        title,
        metadescription,
        about,
        abstract,
        stepByStep,
        //contentType,
        keywords,
        // tags,
        solutions,
        simplifiedText,
        URL,
    } = data;

    

    return (
        <div className="p-4 md:p-10 max-w-7xl mx-auto space-y-8">
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metadescription} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={metadescription} />
            <meta property="og:image" content="https://dl3.pushbulletusercontent.com/lG0famLWiwgOjJ1P0vj4zxv3UJ1JU9Jg/Ideas.png" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metadescription} />
            <meta name="twitter:image" content={URL} />

        </Helmet>
            {/* Title and Meta */}
            <header className="text-left md:text-left">
                <h1 className="text-3xl md:text-5xl font-bold text-black-600 mb-4">
                    {title}
                </h1>

                <div >
                    <div className="flex flex-wrap gap-2">
                        {keywords.map((keyword, index) => (
                            <span
                                key={index}
                                className="bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-sm"
                            >
                                {keyword}
                            </span>
                        ))}
                    </div>
                </div>


            </header>

            {/* About and Abstract */}
            <section >
                <p className="text-gray-600">{about} {abstract}</p>
            </section>

            <section>
                {/* Share to whatsapp current URL */}
                <a href={`https://wa.me/?text=https://app.zalva.me/item/${idItem}`} className="bg-green-500 text-white px-4 py-2 rounded-md"> Compartir en WhatsApp</a>
            </section>

            {/* Simplified Text */}
            <section>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    Resumen
                </h2>
                <p className="text-gray-600">{simplifiedText}</p>
            </section>

            {/* Paso a paso */}
            <section>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Paso a paso</h2>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                    {stepByStep.map((step, index) => (
                        <li key={index}>{step}</li>
                    ))}
                </ul>
            </section>

            {/* Solutions */}
            <section>
                <Solutions solutions={solutions} />
            </section>

            {/* Reference URL */}
            <section>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    URL del contenido original
                </h2>
                <a
                    href={URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                >
                    {URL}
                </a>
            </section>
        </div>
    );
};

export default Item;
