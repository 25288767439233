import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Library from './views/Library';
import Item from './views/Item';

const App = () => {
  return (
    <div>
      <Routes>
        {/* Define the routes */}
        <Route path="/library" element={<Library />} />
        <Route path="/item/:idItem" element={<Item />} />
      </Routes>
    </div>
  );
};

export default App;
