import React, { useState } from "react";

const FAQs = ({ solutions }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleCollapse = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      {solutions.map((solution, index) => (
        <div key={index} className="mb-4 border border-gray-200 rounded-md">
          <button
            onClick={() => toggleCollapse(index)}
            className="w-full text-left p-4 flex justify-between items-center bg-gray-100 hover:bg-gray-200 focus:outline-none"
          >
            <span className="text-lg font-medium text-gray-700">
              {solution.question}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-5 w-5 transform transition-transform ${
                openIndex === index ? "rotate-180" : "rotate-0"
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 10.586l3.71-3.356a.75.75 0 111.06 1.06l-4 3.62a.75.75 0 01-1.06 0l-4-3.62a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={`transition-all overflow-hidden ${
              openIndex === index ? "max-h-screen p-4 bg-white" : "max-h-0"
            }`}
          >
            <p className="text-gray-600">{solution.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQs;
